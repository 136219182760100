<template>
  <!-- Offcanvas Menu Begin -->
  <div class="offcanvas-menu-overlay"></div>
  <div class="offcanvas-menu-wrapper">
    <div class="offcanvas__option">
      <div class="offcanvas__links">
        <a href="#">Sign in</a>
        <a href="#">FAQs</a>
      </div>
      <div class="offcanvas__top__hover">
        <span>Usd <i class="arrow_carrot-down"></i></span>
        <ul>
          <li>USD</li>
          <li>EUR</li>
          <li>USD</li>
        </ul>
      </div>
    </div>
    <div class="offcanvas__nav__option">
      <a href="#" class="search-switch"
        ><img src="img/icon/search.png" alt=""
      /></a>
      <a href="#"><img src="img/icon/heart.png" alt="" /></a>
      <a href="#"><img src="img/icon/cart.png" alt="" /> <span>0</span></a>
      <div class="price">$0.00</div>
    </div>
    <div id="mobile-menu-wrap"></div>
    <div class="offcanvas__text">
      <p>Free shipping, 30-day return or refund guarantee.</p>
    </div>
  </div>
  <!-- Offcanvas Menu End -->

  <!-- Header Section Begin -->
  <header class="header">
    <div class="header__top">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-7">
            <div class="header__top__left">
              <p>Free shipping, 30-day return or refund guarantee.</p>
            </div>
          </div>
          <div class="col-lg-6 col-md-5">
            <div class="header__top__right">
              <div class="header__top__links">
                <a href="#">Sign in</a>
                <a href="#">FAQs</a>
              </div>
              <div class="header__top__hover">
                <span>Usd <i class="arrow_carrot-down"></i></span>
                <ul>
                  <li>USD</li>
                  <li>EUR</li>
                  <li>USD</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3">
          <div class="header__logo">
            <h3>Mika Fashion</h3>
            <!-- <a href="./index.html"><img src="img/logo.png" alt="" /></a> -->
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <nav class="header__menu mobile-menu">
            <ul>
              <li class="active"><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/shop">Shop</a></li>
              <li><a href="/blog">Blog</a></li>
              <li><a href="/contact">Contacts</a></li>
            </ul>
          </nav>
        </div>
        <div class="col-lg-3 col-md-3">
          <div class="header__nav__option">
            <a href="#" class="search-switch"
              ><img src="img/icon/search.png" alt=""
            /></a>
            <a href="#"><img src="img/icon/heart.png" alt="" /></a>
            <a href="#"
              ><img src="img/icon/cart.png" alt="" /> <span>0</span></a
            >
            <div class="price">$0.00</div>
          </div>
        </div>
      </div>
      <div class="canvas__open"><i class="fa fa-bars"></i></div>
    </div>
  </header>
  <!-- Header Section End -->

  <!-- Hero Section Begin -->
  <section class="hero">
    <div id="header-carousel" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active" style="height: 100 %">
              <img class="img-fluid" src="img/hero/hero-2.jpg" alt="Image" />
              <div
                class="carousel-caption d-flex flex-column align-items-center justify-content-center"
              >
                <div class="p-3" style="max-width: 700px">
                  <h4 class="text-light text-uppercase font-weight-medium mb-3" style="color: black;">
                    10% Off Your First Order
                  </h4>
                  <h3 class="display-4 text-white font-weight-semi-bold mb-4" style="color: black;">
                    Fashionable Dress
                  </h3>
                  <a href="" class="btn btn-light py-2 px-3" style="color: black;">Shop Now</a>
                </div>
              </div>
            </div>
            <div class="carousel-item" style="height: 100 %">
              <img class="img-fluid" src="img/hero/hero-2.jpg" alt="Image" />
              <div
                class="carousel-caption d-flex flex-column align-items-center justify-content-center"
              >
                <div class="p-3" style="max-width: 700px">
                  <h4 class="text-light text-uppercase font-weight-medium mb-3" style="color: black;">
                    10% Off Your First Order
                  </h4>
                  <h3 class="display-4 text-white font-weight-semi-bold mb-4" style="color: black;">
                    Reasonable Price
                  </h3>
                  <a href="" class="btn btn-light py-2 px-3" style="color: black;">Shop Now</a>
                </div>
              </div>
            </div>
            <div class="carousel-item" style="height: 100 %">
              <img class="img-fluid" src="img/hero/hero-2.jpg" alt="Image" />
              <div
                class="carousel-caption d-flex flex-column align-items-center justify-content-center"
              >
                <div class="p-3" style="max-width: 700px">
                  <h4 class="text-light text-uppercase font-weight-medium mb-3" style="color: black;">
                    10% Off Your First Order
                  </h4>
                  <h3 class="display-4 text-white font-weight-semi-bold mb-4" style="color: black;">
                    Reasonable Price
                  </h3>
                  <a href="" class="btn btn-light py-2 px-3" style="color: black;">Shop Now</a>
                </div>
              </div>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#header-carousel"
            data-slide="prev"
          >
            <div class="btn btn-dark" style="width: 45px; height: 45px">
              <span class="carousel-control-prev-icon mb-n2"></span>
            </div>
          </a>
          <a
            class="carousel-control-next"
            href="#header-carousel"
            data-slide="next"
          >
            <div class="btn btn-dark" style="width: 45px; height: 45px">
              <span class="carousel-control-next-icon mb-n2"></span>
            </div>
          </a>
        </div>
  </section>
  <!-- Hero Section End -->

  <!-- Banner Section Begin -->
  <section class="banner spad">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 offset-lg-4">
          <div class="banner__item">
            <div class="banner__item__pic">
              <img src="img/banner/banner-1.jpg" alt="" />
            </div>
            <div class="banner__item__text">
              <h2>Clothing Collections 2030</h2>
              <a href="#">Shop now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="banner__item banner__item--middle">
            <div class="banner__item__pic">
              <img src="img/banner/banner-2.jpg" alt="" />
            </div>
            <div class="banner__item__text">
              <h2>Accessories</h2>
              <a href="#">Shop now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="banner__item banner__item--last">
            <div class="banner__item__pic">
              <img src="img/banner/banner-3.jpg" alt="" />
            </div>
            <div class="banner__item__text">
              <h2>Shoes Spring 2030</h2>
              <a href="#">Shop now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Banner Section End -->

  <!-- Product Section Begin -->
  <section class="product spad">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <ul class="filter__controls">
            <li class="active" data-filter="*">Best Sellers</li>
            <li data-filter=".new-arrivals">New Arrivals</li>
            <li data-filter=".hot-sales">Hot Sales</li>
          </ul>
        </div>
      </div>
      <div class="row product__filter">
        <div
          class="col-lg-3 col-md-6 col-sm-6 col-md-6 col-sm-6 mix new-arrivals"
        >
          <div class="product__item">
            <div
              class="product__item__pic set-bg"
              :style="{ backgroundImage: `url('img/product/product-1.jpg')` }"
            >
              <span class="label">New</span>
              <ul class="product__hover">
                <li>
                  <a href="#"><img src="img/icon/heart.png" alt="" /></a>
                </li>
                <li>
                  <a href="#"
                    ><img src="img/icon/compare.png" alt="" />
                    <span>Compare</span></a
                  >
                </li>
                <li>
                  <a href="#"><img src="img/icon/search.png" alt="" /></a>
                </li>
              </ul>
            </div>
            <div class="product__item__text">
              <h6>Piqué Biker Jacket</h6>
              <a href="#" class="add-cart">+ Add To Cart</a>
              <div class="rating">
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
              </div>
              <h5>$67.24</h5>
              <div class="product__color__select">
                <label for="pc-1">
                  <input type="radio" id="pc-1" />
                </label>
                <label class="active black" for="pc-2">
                  <input type="radio" id="pc-2" />
                </label>
                <label class="grey" for="pc-3">
                  <input type="radio" id="pc-3" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-md-6 col-sm-6 mix hot-sales">
          <div class="product__item">
            <div
              class="product__item__pic set-bg"
              :style="{ backgroundImage: `url('img/product/product-2.jpg')` }"
            >
              <ul class="product__hover">
                <li>
                  <a href="#"><img src="img/icon/heart.png" alt="" /></a>
                </li>
                <li>
                  <a href="#"
                    ><img src="img/icon/compare.png" alt="" />
                    <span>Compare</span></a
                  >
                </li>
                <li>
                  <a href="#"><img src="img/icon/search.png" alt="" /></a>
                </li>
              </ul>
            </div>
            <div class="product__item__text">
              <h6>Piqué Biker Jacket</h6>
              <a href="#" class="add-cart">+ Add To Cart</a>
              <div class="rating">
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
              </div>
              <h5>$67.24</h5>
              <div class="product__color__select">
                <label for="pc-4">
                  <input type="radio" id="pc-4" />
                </label>
                <label class="active black" for="pc-5">
                  <input type="radio" id="pc-5" />
                </label>
                <label class="grey" for="pc-6">
                  <input type="radio" id="pc-6" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-sm-6 col-md-6 col-sm-6 mix new-arrivals"
        >
          <div class="product__item sale">
            <div
              class="product__item__pic set-bg"
              :style="{ backgroundImage: `url('img/product/product-3.jpg')` }"
            >
              <span class="label">Sale</span>
              <ul class="product__hover">
                <li>
                  <a href="#"><img src="img/icon/heart.png" alt="" /></a>
                </li>
                <li>
                  <a href="#"
                    ><img src="img/icon/compare.png" alt="" />
                    <span>Compare</span></a
                  >
                </li>
                <li>
                  <a href="#"><img src="img/icon/search.png" alt="" /></a>
                </li>
              </ul>
            </div>
            <div class="product__item__text">
              <h6>Multi-pocket Chest Bag</h6>
              <a href="#" class="add-cart">+ Add To Cart</a>
              <div class="rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star-o"></i>
              </div>
              <h5>$43.48</h5>
              <div class="product__color__select">
                <label for="pc-7">
                  <input type="radio" id="pc-7" />
                </label>
                <label class="active black" for="pc-8">
                  <input type="radio" id="pc-8" />
                </label>
                <label class="grey" for="pc-9">
                  <input type="radio" id="pc-9" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-md-6 col-sm-6 mix hot-sales">
          <div class="product__item">
            <div
              class="product__item__pic set-bg"
              :style="{ backgroundImage: `url('img/product/product-4.jpg')` }"
            >
              <ul class="product__hover">
                <li>
                  <a href="#"><img src="img/icon/heart.png" alt="" /></a>
                </li>
                <li>
                  <a href="#"
                    ><img src="img/icon/compare.png" alt="" />
                    <span>Compare</span></a
                  >
                </li>
                <li>
                  <a href="#"><img src="img/icon/search.png" alt="" /></a>
                </li>
              </ul>
            </div>
            <div class="product__item__text">
              <h6>Diagonal Textured Cap</h6>
              <a href="#" class="add-cart">+ Add To Cart</a>
              <div class="rating">
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
              </div>
              <h5>$60.9</h5>
              <div class="product__color__select">
                <label for="pc-10">
                  <input type="radio" id="pc-10" />
                </label>
                <label class="active black" for="pc-11">
                  <input type="radio" id="pc-11" />
                </label>
                <label class="grey" for="pc-12">
                  <input type="radio" id="pc-12" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-sm-6 col-md-6 col-sm-6 mix new-arrivals"
        >
          <div class="product__item">
            <div
              class="product__item__pic set-bg"
              :style="{ backgroundImage: `url('img/product/product-5.jpg')` }"
            >
              <ul class="product__hover">
                <li>
                  <a href="#"><img src="img/icon/heart.png" alt="" /></a>
                </li>
                <li>
                  <a href="#"
                    ><img src="img/icon/compare.png" alt="" />
                    <span>Compare</span></a
                  >
                </li>
                <li>
                  <a href="#"><img src="img/icon/search.png" alt="" /></a>
                </li>
              </ul>
            </div>
            <div class="product__item__text">
              <h6>Lether Backpack</h6>
              <a href="#" class="add-cart">+ Add To Cart</a>
              <div class="rating">
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
              </div>
              <h5>$31.37</h5>
              <div class="product__color__select">
                <label for="pc-13">
                  <input type="radio" id="pc-13" />
                </label>
                <label class="active black" for="pc-14">
                  <input type="radio" id="pc-14" />
                </label>
                <label class="grey" for="pc-15">
                  <input type="radio" id="pc-15" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-md-6 col-sm-6 mix hot-sales">
          <div class="product__item sale">
            <div
              class="product__item__pic set-bg"
              :style="{ backgroundImage: `url('img/product/product-6.jpg')` }"
            >
              <span class="label">Sale</span>
              <ul class="product__hover">
                <li>
                  <a href="#"><img src="img/icon/heart.png" alt="" /></a>
                </li>
                <li>
                  <a href="#"
                    ><img src="img/icon/compare.png" alt="" />
                    <span>Compare</span></a
                  >
                </li>
                <li>
                  <a href="#"><img src="img/icon/search.png" alt="" /></a>
                </li>
              </ul>
            </div>
            <div class="product__item__text">
              <h6>Ankle Boots</h6>
              <a href="#" class="add-cart">+ Add To Cart</a>
              <div class="rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star-o"></i>
              </div>
              <h5>$98.49</h5>
              <div class="product__color__select">
                <label for="pc-16">
                  <input type="radio" id="pc-16" />
                </label>
                <label class="active black" for="pc-17">
                  <input type="radio" id="pc-17" />
                </label>
                <label class="grey" for="pc-18">
                  <input type="radio" id="pc-18" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-sm-6 col-md-6 col-sm-6 mix new-arrivals"
        >
          <div class="product__item">
            <div
              class="product__item__pic set-bg"
              :style="{ backgroundImage: `url('img/product/product-7.jpg')` }"
            >
              <ul class="product__hover">
                <li>
                  <a href="#"><img src="img/icon/heart.png" alt="" /></a>
                </li>
                <li>
                  <a href="#"
                    ><img src="img/icon/compare.png" alt="" />
                    <span>Compare</span></a
                  >
                </li>
                <li>
                  <a href="#"><img src="img/icon/search.png" alt="" /></a>
                </li>
              </ul>
            </div>
            <div class="product__item__text">
              <h6>T-shirt Contrast Pocket</h6>
              <a href="#" class="add-cart">+ Add To Cart</a>
              <div class="rating">
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
              </div>
              <h5>$49.66</h5>
              <div class="product__color__select">
                <label for="pc-19">
                  <input type="radio" id="pc-19" />
                </label>
                <label class="active black" for="pc-20">
                  <input type="radio" id="pc-20" />
                </label>
                <label class="grey" for="pc-21">
                  <input type="radio" id="pc-21" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-md-6 col-sm-6 mix hot-sales">
          <div class="product__item">
            <div
              class="product__item__pic set-bg"
              :style="{ backgroundImage: `url('img/product/product-8.jpg')` }"
            >
              <ul class="product__hover">
                <li>
                  <a href="#"><img src="img/icon/heart.png" alt="" /></a>
                </li>
                <li>
                  <a href="#"
                    ><img src="img/icon/compare.png" alt="" />
                    <span>Compare</span></a
                  >
                </li>
                <li>
                  <a href="#"><img src="img/icon/search.png" alt="" /></a>
                </li>
              </ul>
            </div>
            <div class="product__item__text">
              <h6>Basic Flowing Scarf</h6>
              <a href="#" class="add-cart">+ Add To Cart</a>
              <div class="rating">
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
                <i class="fa fa-star-o"></i>
              </div>
              <h5>$26.28</h5>
              <div class="product__color__select">
                <label for="pc-22">
                  <input type="radio" id="pc-22" />
                </label>
                <label class="active black" for="pc-23">
                  <input type="radio" id="pc-23" />
                </label>
                <label class="grey" for="pc-24">
                  <input type="radio" id="pc-24" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Product Section End -->

  <!-- Categories Section Begin -->
  <section class="categories spad">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="categories__text">
            <h2>
              Clothings Hot <br />
              <span>Shoe Collection</span> <br />
              Accessories
            </h2>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="categories__hot__deal">
            <img src="img/product-sale.png" alt="" />
            <div class="hot__deal__sticker">
              <span>Sale Of</span>
              <h5>$29.99</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 offset-lg-1">
          <div class="categories__deal__countdown">
            <span>Deal Of The Week</span>
            <h2>Multi-pocket Chest Bag Black</h2>
            <div class="categories__deal__countdown__timer" id="countdown">
              <div class="cd-item">
                <span>3</span>
                <p>Days</p>
              </div>
              <div class="cd-item">
                <span>1</span>
                <p>Hours</p>
              </div>
              <div class="cd-item">
                <span>50</span>
                <p>Minutes</p>
              </div>
              <div class="cd-item">
                <span>18</span>
                <p>Seconds</p>
              </div>
            </div>
            <a href="#" class="primary-btn">Shop now</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Categories Section End -->

  <!-- Instagram Section Begin -->
  <section class="instagram spad">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="instagram__pic">
            <div
              class="instagram__pic__item set-bg"
              :style="{
                backgroundImage: `url('img/instagram/instagram-1.jpg')`,
              }"
            ></div>
            <div
              class="instagram__pic__item set-bg"
              :style="{
                backgroundImage: `url('img/instagram/instagram-2.jpg')`,
              }"
            ></div>
            <div
              class="instagram__pic__item set-bg"
              :style="{
                backgroundImage: `url('img/instagram/instagram-3.jpg')`,
              }"
            ></div>
            <div
              class="instagram__pic__item set-bg"
              :style="{
                backgroundImage: `url('img/instagram/instagram-4.jpg')`,
              }"
            ></div>
            <div
              class="instagram__pic__item set-bg"
              :style="{
                backgroundImage: `url('img/instagram/instagram-5.jpg')`,
              }"
            ></div>
            <div
              class="instagram__pic__item set-bg"
              :style="{
                backgroundImage: `url('img/instagram/instagram-6.jpg')`,
              }"
            ></div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="instagram__text">
            <h2>Instagram</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <h3>#Mika Fashion</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Instagram Section End -->

  <!-- Latest Blog Section Begin -->
  <section class="latest spad">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">
            <span>Latest News</span>
            <h2>Fashion New Trends</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="blog__item">
            <div
              class="blog__item__pic set-bg"
              :style="{ backgroundImage: `url('img/blog/blog-1.jpg')` }"
            ></div>
            <div class="blog__item__text">
              <span
                ><img src="img/icon/calendar.png" alt="" /> 16 February
                2020</span
              >
              <h5>What Curling Irons Are The Best Ones</h5>
              <a href="#">Read More</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="blog__item">
            <div
              class="blog__item__pic set-bg"
              :style="{ backgroundImage: `url('img/blog/blog-2.jpg')` }"
            ></div>
            <div class="blog__item__text">
              <span
                ><img src="img/icon/calendar.png" alt="" /> 21 February
                2020</span
              >
              <h5>Eternity Bands Do Last Forever</h5>
              <a href="#">Read More</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="blog__item">
            <div
              class="blog__item__pic set-bg"
              :style="{ backgroundImage: `url('img/blog/blog-3.jpg')` }"
            ></div>
            <div class="blog__item__text">
              <span
                ><img src="img/icon/calendar.png" alt="" /> 28 February
                2020</span
              >
              <h5>The Health Benefits Of Sunglasses</h5>
              <a href="#">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Latest Blog Section End -->

  <!-- Footer Section Begin -->
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="footer__about">
            <div class="footer__logo">
              <!-- <a href="#"><img src="img/footer-logo.png" alt="" /></a> -->
              <h3 style="color: white">Mika Fashion</h3>
            </div>
            <p>
              The customer is at the heart of our unique business model, which
              includes design.
            </p>
            <a href="#"><img src="img/payment.png" alt="" /></a>
          </div>
        </div>
        <div class="col-lg-2 offset-lg-1 col-md-3 col-sm-6">
          <div class="footer__widget">
            <h6>Shopping</h6>
            <ul>
              <li><a href="#">Clothing Store</a></li>
              <li><a href="#">Trending Shoes</a></li>
              <li><a href="#">Accessories</a></li>
              <li><a href="#">Sale</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="footer__widget">
            <h6>Shopping</h6>
            <ul>
              <li><a href="#">Contact Us</a></li>
              <li><a href="#">Payment Methods</a></li>
              <li><a href="#">Delivary</a></li>
              <li><a href="#">Return & Exchanges</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 offset-lg-1 col-md-6 col-sm-6">
          <div class="footer__widget">
            <h6>NewLetter</h6>
            <div class="footer__newslatter">
              <p>
                Be the first to know about new arrivals, look books, sales &
                promos!
              </p>
              <form action="#">
                <input type="text" placeholder="Your email" />
                <button type="submit">
                  <span class="icon_mail_alt"></span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="footer__copyright__text">
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
            <p>
              Copyright © 2024 All rights reserved | This template is made with
              <i class="fa fa-heart-o" aria-hidden="true"></i> by
              <a href="https://portfolio-ganza.pages.dev/" target="_blank"
                >Heritier Ganza Tamba</a
              >
            </p>
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer Section End -->

  <!-- Search Begin -->
  <div class="search-model">
    <div class="h-100 d-flex align-items-center justify-content-center">
      <div class="search-close-switch">+</div>
      <form class="search-model-form">
        <input type="text" id="search-input" placeholder="Search here....." />
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      heroImage: 'img/hero/hero-1.jpg'
    }
  }
}
</script>
<style scoped>
.hero {
  position: relative;
  height: 800px; /* Adjust height as needed */
  overflow: hidden;
}

.hero__container {
  display: flex;
  height: 100%;
}

.hero__slider {
  flex: 1;
  position: relative;
}

.hero__content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.hero__content h6 {
  margin-bottom: 10px;
  font-size: 18px;
}

.hero__content h2 {
  margin-bottom: 20px;
  font-size: 48px;
  line-height: 1.2;
}

.hero__content p {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.5;
}

.hero__content .primary-btn {
  background-color: #fff;
  color: #333;
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.hero__content .primary-btn:hover {
  background-color: #f2f2f2;
}

/* Navigation buttons (optional) */
.hero__slider .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.hero__slider .owl-nav button.owl-prev {
  left: 10px;
}

.hero__slider .owl-nav button.owl-next {
  right: 10px;
}

/* Media queries (optional) */
@media (max-width: 768px) {
  .hero__content h2 {
    font-size: 32px;
  }

  .hero__content p {
    font-size: 14px;
  }
}
</style>
